var faqInserts = document.querySelectorAll('div.faqinsert');
if (faqInserts.length > 0) {
    var _loop_2 = function (i) {
        faqInserts[i].setAttribute('style', '');
        var faqConfigTmp = faqInserts[i].innerHTML.split('=')[1].split('|');
        faqConfigTmp[faqConfigTmp.length - 1] = faqConfigTmp[faqConfigTmp.length - 1].slice(0, -1);

        var request = new XMLHttpRequest();
        request.open('GET', '/ajaxNoTracy/getFaqById/' + faqConfigTmp[0], true);
        request.onload = function() {
            if (request.status >= 200 && request.status < 400) {
                faqInserts[i].innerHTML = request.responseText;

                var questionElements = document.querySelectorAll('.faq .item .question');

                questionElements.forEach(function (questionElement) {
                    questionElement.addEventListener('click', function () {
                        var answerElement = this.parentElement.querySelector('.answer');
                        if (answerElement) {
                            if (answerElement.style.display === 'none' || answerElement.style.display === '') {
                                answerElement.style.display = 'block';
                            } else {
                                answerElement.style.display = 'none';
                            }
                        }
                    });
                });
            }
        };
        request.send();

    };
    for (var i = 0; i < faqInserts.length; i++) {
        _loop_2(i);
    }
}


function setFaqFeedback(id, type)
{
    var url = '/faq/feedback/' + id + '/' + type;
    var request = new XMLHttpRequest();
    request.open('GET', url, true);
    request.onload = function() {
        if (request.status >= 200 && request.status < 400) {
            var data = JSON.parse(request.responseText);
            var feedbackElement = document.getElementById('feedback-' + id);
            if (feedbackElement) {
                feedbackElement.innerHTML = data.feedback;
            }
        }
    };
    request.send();
}
